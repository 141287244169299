import data from "../assets/data.json";

function onSelectTab(tabName, setFilters) {
    try {
        const goods = data[tabName];
        const vendor = {
            "variants": goods['vendor'],
            "selected": [],
            name: "Производитель"
        };
        const diameter = {
            "variants": goods['diameter'],
            "selected": [],
            name: "Диаметр"
        };
        setFilters({vendor, diameter});
    } catch (e) {
        console.log("Unknown tab name: " + tabName, e);
        setFilters({});
    }
}

const TabList = [
    {"name": "electrode", "title": "Сварочные электроды"},
    {"name": "wire", "title": "Сварочная проволока"},
];

const Palette = {
    "primaryColor": "#bb2d3b",
}

export {onSelectTab, TabList, Palette}