import {Modal} from "react-bootstrap";
import './ModalShowStoreItem.css';
import {ReactComponent as Close} from "../../assets/close.svg";

function ModalShowStoreItem({show, onHide, data}) {
    return (
        <Modal show={show}
               onHide={onHide}
               centered
               backdrop={true}>
            <Modal.Body>
                <div className="top_part">
                    {data && <img src={require("../../../public/assets/goodsLogo/" + data.logoPath)} alt="item logo"
                                  className="modalImage" loading="lazy"/>}
                    <div className="modalName">
                        <div className="modalNameExactly">{data.name}</div>
                        <div className="modalNameDiameters">Диаметр: {data.diameter}</div>
                        <div className="modelNamePrice">{data.price} ₽ за КГ <span className="nonFinalPrice">(ориентировочная цена)</span></div>
                    </div>
                    <div className="closeButton" onClick={onHide}>
                        <Close className="closeButtonImage"/>
                    </div>
                </div>
                <div className="bottom_part">
                    <div className="description_description">Описание</div>
                    <div className="modalDescriptionExactly">{data.description}</div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ModalShowStoreItem